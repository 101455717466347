// External Dependencies
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby';

// Internal Dependencies
import CurveSection from '../components/shared/CurveSection';
import EnhancedAccordion from '../components/shared/EnhancedAccordion';
import EnhancedAccordionSummary from '../components/shared/EnhancedAccordionSummary';
import HeaderCurve from '../components/HeaderCurve';
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Local Variables
const mailToBillingString = 'mailto:billing@presto-assistant.com?subject=Question about purchasing licenses';
const mailToBillingEncodedString = encodeURI(mailToBillingString);

// Component definition
const Faqs = () => {
  return (
    <Layout variant="curve">
      <Meta title="FAQs" />

      <HeaderCurve
        subtitle="Find answers to common questions heard by Team Presto."
        title="You've got questions, we've got answers!"
      />

      <div className="switch" id="main">
        <CurveSection
          className="main"
          id="content"
        >
          <Container maxWidth="md">
            <Typography
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '16px',
              }}
              variant="h4"
            >
              Frequently Asked Questions
            </Typography>
          </Container>
        </CurveSection>

        <Container maxWidth="md">
          <Box marginY={2}>
            <EnhancedAccordion defaultExpanded>
              <EnhancedAccordionSummary panelNumber={1}>
                <Typography
                  className="accordion-panel-title"
                  component="h4"
                >
                  Why is Presto Assistant a good choice?
                </Typography>
              </EnhancedAccordionSummary>

              <AccordionDetails>
                <Typography paragraph variant="body2">
                  Founded and owned by former music educators, the Presto Assistant
                  team has decades of classroom experience, and we understand
                  what teachers deal with every day.
                </Typography>

                <Typography variant="body2">
                  Our team thrives on user feedback and makes decisions based
                  on improvements that help teachers and administrators do their best work.
                  As a 100% customer-driven company, we remain uniquely situated to
                  build the tools that educators need.
                </Typography>
              </AccordionDetails>
            </EnhancedAccordion>

            <EnhancedAccordion>
              <EnhancedAccordionSummary panelNumber={2}>
                <Typography
                  className="accordion-panel-title"
                  component="h4"
                >
                  Can I import data from other systems?
                </Typography>
              </EnhancedAccordionSummary>

              <AccordionDetails>
                <Typography paragraph variant="body2">
                  Absolutely you can. This{' '}
                  <a href="https://support.presto-assistant.com/articles/import-student-data/">support article</a>{' '}
                  shows how to import data from other systems, including
                  sending your files from other tools and platforms to our
                  team. We are happy to help with every step of the process.
                </Typography>
              </AccordionDetails>
            </EnhancedAccordion>

            <EnhancedAccordion>
              <EnhancedAccordionSummary panelNumber={3}>
                <Typography
                  className="accordion-panel-title"
                  component="h4"
                >
                  Do I need a license to use Presto Assistant?
                </Typography>
              </EnhancedAccordionSummary>

              <AccordionDetails>
                <Typography paragraph variant="body2">
                  Yes, you can purchase a license for a competitive price. Learn
                  more about purchasing licenses in this{' '}
                  <a href="https://support.presto-assistant.com/articles/purchase-a-presto-license/">support article</a>
                  .{' '}
                  If your district needs to purchase many licenses, we can
                  discuss whether you qualify for a bulk discount.{' '}
                  <a
                    href={mailToBillingEncodedString}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Contact our billing team
                  </a>{' '}
                  with any questions about purchasing licenses.
                </Typography>
              </AccordionDetails>
            </EnhancedAccordion>

            <EnhancedAccordion>
              <EnhancedAccordionSummary panelNumber={4}>
                <Typography
                  className="accordion-panel-title"
                  component="h4"
                >
                  Do we have to sign a long-term contract?
                </Typography>
              </EnhancedAccordionSummary>

              <AccordionDetails>
                <Typography paragraph variant="body2">
                  No contract is needed. When your active license expires,
                  your data is always available to you. Without an active
                  license you won&apos;t be able to add new data or make changes
                  to existing data.
                </Typography>
              </AccordionDetails>
            </EnhancedAccordion>

            <EnhancedAccordion>
              <EnhancedAccordionSummary panelNumber={5}>
                <Typography
                  className="accordion-panel-title"
                  component="h4"
                >
                  Is Presto Assistant FERPA and COPPA compliant?
                </Typography>
              </EnhancedAccordionSummary>

              <AccordionDetails>
                <Typography paragraph variant="body2">
                  Yes, we are FERPA and COPPA compliant. We take data security and privacy
                  very seriously. Our{' '}
                  <Link to="/privacy_policy">privacy policy</Link>{' '}
                  outlines how we handle data and what we do to keep it secure.
                  Our technology team can answer any questions you have about data security.
                </Typography>
              </AccordionDetails>
            </EnhancedAccordion>
          </Box>

          <Box className="panel">
            <Link
              className="transparent-button"
              data-variant="transport"
              to="/contact_support"
            >
              Contact us
            </Link>
          </Box>
        </Container>
      </div>
    </Layout>
  );
};

export default Faqs;

// External Dependencies
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';

// Local Variables
const propTypes = {
  children: PropTypes.node.isRequired,
  panelNumber: PropTypes.number.isRequired,
};

// Component definition
const EnhancedAccordionSummary = ({
  children,
  panelNumber,
}) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`panel${panelNumber}-content`}
      id={`panel${panelNumber}-header`}
    >
      {children}
    </AccordionSummary>
  );
};

EnhancedAccordionSummary.propTypes = propTypes;

export default EnhancedAccordionSummary;
